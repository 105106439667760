import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { ButtonPrimary } from "../../../UI/Buttons";

import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "./InvoiceInfo.module.scss";

import { setLoading, triggerNotification } from "../../../../actions";

const RefundData = ({
	refundData,
	requestId,
	requestTaskId,
	tempId,
	currentUser,
	setLoading,
	triggerNotification,
	t,
	i18n,
	readOnly,
}) => {
	const [refundInfo, setRefundInfo] = useState(() => {
		return refundData ? refundData : {};
	});
	const [refundType, setRefundType] = useState(
		refundData.Invoice_Refund_Type_Id
			? refundData.Invoice_Refund_Type_Id
			: "2"
	);
	const [refundValueType, setRefundValueType] = useState(
		refundData.Insurance_Refund_Value_Type_Id
			? refundData.Insurance_Refund_Value_Type_Id
			: "1"
	);

	const onReturnRequestStepSubmit = (values) => {
		return null;
	};

	const onRefundSubmit = async (values) => {
		let at = currentUser.Data.access_token;

		setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_REFUND_VALUE",
			{
				Request_Id: requestId,
				Request_Task_Id: requestTaskId,
				Temp_Action_Id: tempId,
				Insurance_Refund_Value: values.refundValue,
				Insurance_Refund_Value_Type_Id: refundValueType,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		setLoading(false);

		if (response.data.Status) {
			setRefundInfo(response.data.Data);
		}
	};

	const validate = (values) => {
		const errors = {};

		return errors;
	};

	const handleRefundTypeChange = (e) => {
		setRefundType(e.target.value);
	};

	const handleRefundValueTypeChange = (e) => {
		setRefundValueType(e.target.value);
	};

	const saveRequestRefundType = async (type) => {
		let at = currentUser.Data.access_token;

		setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_REFUND_TYPE",
				{
					Request_Id: requestId,
					Request_Task_Id: requestTaskId,
					Temp_Action_Id: tempId,
					Invoice_Refund_Type_Id: refundType,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				setLoading(false);

				if (res.data.Status) {
					setRefundInfo(res.data.Data);
				} else {
					throw res.data;
				}
			})
			.catch((err) => {
				setLoading(false);
				triggerNotification(true, err);
			});
	};

	useEffect(() => {
		if (!readOnly) {
			saveRequestRefundType(refundType);
		}
	}, [refundType, readOnly]);

	return (
		<>
			{!readOnly && (
				<div className={`row ${style.infoWrap}`}>
					<div className="col-12">
						<h4>{t("refundType")}</h4>
					</div>

					<div className="col-12">
						<Form
							onSubmit={onReturnRequestStepSubmit}
							initialValues={{
								refundType: "2",
							}}
							validate={validate}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form
									id="refundTypeForm"
									onSubmit={handleSubmit}
									noValidate
								>
									<FormControl className={style.radioLabel}>
										<RadioGroup
											row
											name="refundType"
											value={refundType}
											onChange={handleRefundTypeChange}
										>
											{/* <FormControlLabel value="1" control={<Radio />} label={t("check")} /> */}
											<FormControlLabel
												value="2"
												control={<Radio />}
												label={t("bankAcc")}
											/>
											<FormControlLabel
												value="3"
												control={<Radio />}
												label={t("tahseel")}
											/>
											<FormControlLabel
												value="4"
												control={<Radio />}
												label={t("id")}
											/>
										</RadioGroup>
									</FormControl>
								</form>
							)}
						/>
					</div>
				</div>
			)}

			<div className={`row ${style.infoWrap}`}>
				<div className="col-12 mb-3">
					{readOnly ? (
						<p className={style.labelAlt}>
							{t("refundAccountInfo")}
						</p>
					) : (
						<h4 className={style.labelAlt}>
							{t("refundAccountInfo")}
						</h4>
					)}
				</div>

				{refundType == "1" && (
					<div className="col-12">
						<h6>{t("check")}</h6>

						<div className="row">
							{refundInfo?.Account_Name_AR && (
								<div className="col-3">
									<label>{t("ownerNameAr")}</label>
									<p>{refundInfo.Account_Name_AR}</p>
								</div>
							)}

							{refundInfo?.Account_Name_EN && (
								<div className="col-3">
									<label>{t("ownerNameEn")}</label>
									<p>{refundInfo.Account_Name_EN}</p>
								</div>
							)}

							{refundInfo?.License_No && (
								<div className="col-3">
									<label>{t("licenseNo")}</label>
									<p>{refundInfo.License_No}</p>
								</div>
							)}

							{refundInfo?.Email && (
								<div className="col-3">
									<label>{t("email")}</label>
									<p>{refundInfo.Email}</p>
								</div>
							)}

							{refundInfo?.Mobile_No && (
								<div className="col-3">
									<label>{t("phone")}</label>
									<p>{refundInfo.Mobile_No}</p>
								</div>
							)}
						</div>
					</div>
				)}

				{refundType == "2" && (
					<div className="col-12">
						<h6>{t("bankAcc")}</h6>

						<div className="row">
							{refundInfo?.Bank_Name_AR && (
								<div className="col-3">
									<label>{t("bankNameAr")}</label>
									<p>{refundInfo.Bank_Name_AR}</p>
								</div>
							)}

							{refundInfo?.Bank_Name_EN && (
								<div className="col-3">
									<label>{t("bankNameEn")}</label>
									<p>{refundInfo.Bank_Name_EN}</p>
								</div>
							)}

							{refundInfo?.IBAN && (
								<div className="col-3">
									<label>{t("ibanAccount")}</label>
									<p>{refundInfo.IBAN}</p>
								</div>
							)}

							{refundInfo?.Account_Name_EN && (
								<div className="col-3">
									<label>{t("ownerNameEn")}</label>
									<p>{refundInfo.Account_Name_EN}</p>
								</div>
							)}

							{refundInfo?.Mobile_No && (
								<div className="col-3">
									<label>{t("phone")}</label>
									<p>{refundInfo.Mobile_No}</p>
								</div>
							)}

							{refundInfo?.Address && (
								<div className="col-3">
									<label>{t("address")}</label>
									<p>{refundInfo.Address}</p>
								</div>
							)}
						</div>
					</div>
				)}

				{refundType == "3" && (
					<div className="col-12">
						<h6>{t("tahseel")}</h6>

						<div className="row">
							{refundInfo?.Online_Reference_No && (
								<div className="col-3">
									<label>{t("onlineRefNo")}</label>
									<p>{refundInfo.Account_No}</p>
								</div>
							)}

							{refundInfo?.Account_Name_AR && (
								<div className="col-3">
									<label>{t("ownerNameAr")}</label>
									<p>{refundInfo.Account_Name_AR}</p>
								</div>
							)}

							{refundInfo?.Account_Name_EN && (
								<div className="col-3">
									<label>{t("ownerNameEn")}</label>
									<p>{refundInfo.Account_Name_EN}</p>
								</div>
							)}

							{refundInfo?.Email && (
								<div className="col-3">
									<label>{t("email")}</label>
									<p>{refundInfo.Email}</p>
								</div>
							)}

							{refundInfo?.Mobile_No && (
								<div className="col-3">
									<label>{t("phone")}</label>
									<p>{refundInfo.Mobile_No}</p>
								</div>
							)}

							{refundInfo?.Birthdate && (
								<div className="col-3">
									<label>{t("birthdate")}</label>
									<p>
										{moment(refundInfo.Birthdate).format(
											"YYYY-MM-DD"
										)}
									</p>
								</div>
							)}
						</div>
					</div>
				)}

				{refundType == "4" && (
					<div className="col-12">
						<h6>{t("id")}</h6>

						<div className="row">
							{refundInfo?.Account_No && (
								<div className="col-3">
									<label>{t("id")}</label>
									<p>{refundInfo.Account_No}</p>
								</div>
							)}

							{refundInfo?.Account_Name_AR && (
								<div className="col-3">
									<label>{t("ownerNameAr")}</label>
									<p>{refundInfo.Account_Name_AR}</p>
								</div>
							)}

							{refundInfo?.Account_Name_EN && (
								<div className="col-3">
									<label>{t("ownerNameEn")}</label>
									<p>{refundInfo.Account_Name_EN}</p>
								</div>
							)}

							{refundInfo?.Email && (
								<div className="col-3">
									<label>{t("email")}</label>
									<p>{refundInfo.Email}</p>
								</div>
							)}

							{refundInfo?.Mobile_No && (
								<div className="col-3">
									<label>{t("phone")}</label>
									<p>{refundInfo.Mobile_No}</p>
								</div>
							)}

							{refundInfo?.Birthdate && (
								<div className="col-3">
									<label>{t("birthdate")}</label>
									<p>
										{moment(refundInfo.Birthdate).format(
											"YYYY-MM-DD"
										)}
									</p>
								</div>
							)}
						</div>
					</div>
				)}

				{!readOnly && (
					<div
						className={`row ${style.infoWrap} align-items-center w-100`}
					>
						<div className="col-12">
							<h4>{t("refundedValue")}</h4>
						</div>

						<div className="col-6">
							<Form
								onSubmit={onRefundSubmit}
								initialValues={{
									refundValueType: "1",
									refundValue:
										refundInfo?.Tahseel_Invoice_Value || "",
								}}
								validate={validate}
								render={({
									handleSubmit,
									form,
									submitting,
									pristine,
									values,
								}) => (
									<form
										id="refundForm"
										className="row align-items-center"
										onSubmit={handleSubmit}
										noValidate
									>
										<div className="col-6">
											<TextField
												label={t("value")}
												name="refundValue"
												required={true}
											/>
										</div>

										{/* <div className="col-4">
                                <FormControl className={style.radioLabel}>
                                    <RadioGroup row name="refundValueType" value={refundValueType} onChange={handleRefundValueTypeChange}>
                                        <FormControlLabel value="1" control={<Radio />} label={t("value")} />
                                        <FormControlLabel value="2" control={<Radio />} label={t("rate")} />
                                    </RadioGroup>

                                </FormControl>
                            </div> */}

										<div className="col-6">
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={submitting}
												type="submit"
											>
												{t("calculateRefundedValue")}
											</ButtonPrimary>
										</div>
									</form>
								)}
							/>
						</div>

						<div className="col-3">
							<label>{t("refundValue")}</label>
							<p>{refundInfo?.Tahseel_Invoice_Value | 0}</p>
						</div>

						<div className="col-3">
							<label>{t("finalValue")}</label>
							<p>{refundInfo.Total_Retain_Value | 0}</p>
						</div>

						{!!refundInfo?.Total_Retain_Value && (
							<div className="col-12">
								<div
									style={{
										width: "50%",
										padding: "14px",
										border: "2px solid #333",
										color: "red",
										textAlign: "center",
										margin: "28px auto",
										fontSize: "22px",
										fontWeight: "bold",
									}}
								>
									{t("finalValue") +
										": " +
										refundInfo.Total_Retain_Value}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { setLoading, triggerNotification })(
	translationHook(RefundData)
);
