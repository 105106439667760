import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "./InvoiceInfo.module.scss";

import { setLoading, triggerNotification } from "../../../../actions";

import RefundData from "./RefundData";

const InvoiceInfo = ({
	invoiceId,
	requestId,
	requestTaskId,
	tempId,
	currentUser,
	setLoading,
	triggerNotification,
	t,
	i18n,
	readOnly,
	insuranceRefundData,
}) => {
	const [invoiceInfo, setInvoiceInfo] = useState({});
	const [refundData, setRefundData] = useState(() => {
		return insuranceRefundData ? insuranceRefundData : {};
	});

	const getRefundInvoiceInfo = async (invoiceId) => {
		let at = currentUser.Data.access_token;

		setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_REFUND_INVOICE_INFO",
				{
					Request_Id: requestId,
					Request_Task_Id: requestTaskId,
					Temp_Action_Id: tempId,
					Invoice_Id: invoiceId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				setLoading(false);

				if (res.data.Status) {
					setInvoiceInfo(res.data.Data.Invoice_Info);
					setRefundData(res.data.Data.Insurance_Refund_Data);
				} else {
					throw res.data;
				}
			})
			.catch((err) => {
				setLoading(false);
				triggerNotification(true, err);
			});
	};

	useEffect(() => {
		if (!readOnly) {
			if (invoiceId) {
				getRefundInvoiceInfo(invoiceId);
			}
		}
	}, [invoiceId]);

	return (
		<>
			<div className={`row ${style.infoWrap} pt-0`}>
				{Object.keys(refundData).length > 0 && (
					<div className="col-12">
						{readOnly ? (
							<p className={style.labelAlt}>{t("invoiceInfo")}</p>
						) : (
							<h4 className={style.labelAlt}>
								{t("invoiceInfo")}
							</h4>
						)}
					</div>
				)}

				{invoiceInfo?.Invoice_No && (
					<div className="col-3">
						<label>{t("invoiceNo")}</label>
						<p>{invoiceInfo.Invoice_No}</p>
					</div>
				)}

				{(invoiceInfo?.Payment_No ||
					insuranceRefundData?.Payment_No) && (
					<div className="col-3">
						<label>{t("paymentNo")}</label>
						<p>
							{invoiceInfo?.Payment_No
								? invoiceInfo.Payment_No
								: insuranceRefundData?.Payment_No}
						</p>
					</div>
				)}

				{(invoiceInfo?.Online_Reference_No ||
					insuranceRefundData?.Online_Reference_No) && (
					<div className="col-3">
						<label>{t("insuranceOnlineRefNo")}</label>
						<p>
							{invoiceInfo?.Online_Reference_No
								? invoiceInfo.Online_Reference_No
								: insuranceRefundData?.Online_Reference_No}
						</p>
					</div>
				)}

				{(invoiceInfo?.Invoice_Value ||
					insuranceRefundData?.Invoice_Value) && (
					<div className="col-3">
						<label>{t("insuranceValue")}</label>
						<p>
							{invoiceInfo?.Invoice_Value
								? invoiceInfo.Invoice_Value
								: insuranceRefundData?.Invoice_Value}
						</p>
					</div>
				)}

				{(invoiceInfo?.Invoice_Paid_Date ||
					insuranceRefundData?.Invoice_Paid_Date) && (
					<div className="col-3">
						<label>{t("paymentDate")}</label>
						<p>
							{invoiceInfo?.Invoice_Paid_Date
								? invoiceInfo.Invoice_Paid_Date.slice(0, 10)
								: insuranceRefundData?.Invoice_Paid_Date.slice(
										0,
										10
								  )}
						</p>
					</div>
				)}

				{(invoiceInfo?.Invoice_Paid_Value ||
					insuranceRefundData?.Invoice_Paid_Value) && (
					<div className="col-3">
						<label>{t("paidValue")}</label>
						<p>
							{invoiceInfo?.Invoice_Paid_Value
								? invoiceInfo.Invoice_Paid_Value
								: insuranceRefundData?.Invoice_Paid_Value}
						</p>
					</div>
				)}

				{(invoiceInfo?.Invoice_Payer_Name_EN ||
					invoiceInfo?.Invoice_Payer_Name_AR) && (
					<div className="col-3">
						<label>{t("insuranceOwnerName")}</label>
						<p>
							{i18n.language === "ar"
								? invoiceInfo.Invoice_Payer_Name_AR
								: invoiceInfo?.Invoice_Payer_Name_EN}
						</p>
					</div>
				)}

				{(insuranceRefundData?.Account_Name_EN ||
					insuranceRefundData?.Account_Name_AR) && (
					<div className="col-3">
						<label>{t("ownerName")}</label>
						<p>
							{i18n.language === "ar" &&
							insuranceRefundData?.Account_Name_AR
								? insuranceRefundData.Account_Name_AR
								: insuranceRefundData?.Account_Name_EN}
						</p>
					</div>
				)}

				{(invoiceInfo?.Invoice_Type_Name_AR ||
					invoiceInfo?.Invoice_Type_Name_AR) && (
					<div className="col-3">
						<label>{t("invoiceType")}</label>
						<p>
							{i18n.language === "ar"
								? invoiceInfo.Invoice_Type_Name_AR
								: invoiceInfo?.Invoice_Type_Name_EN}
						</p>
					</div>
				)}

				{insuranceRefundData?.Total_Refund_Value ? (
					<div className="col-3">
						<label>{t("refundValue")}</label>
						<p>{insuranceRefundData.Total_Refund_Value}</p>
					</div>
				) : null}

				{insuranceRefundData?.Total_Retain_Value ? (
					<div className="col-3">
						<label>{t("finalValue")}</label>
						<p>{insuranceRefundData.Total_Retain_Value}</p>
					</div>
				) : null}

				{insuranceRefundData?.Total_Retain_Value ? (
					<div className="col-12">
						<div
							style={{
								width: "50%",
								padding: "14px",
								border: "2px solid #333",
								color: "red",
								textAlign: "center",
								margin: "28px auto",
								fontSize: "22px",
								fontWeight: "bold",
							}}
						>
							{t("finalValue") +
								": " +
								insuranceRefundData.Total_Retain_Value}
						</div>
					</div>
				) : null}
			</div>

			{Object.keys(refundData).length > 0 && (
				<RefundData
					requestId={requestId}
					requestTaskId={requestTaskId}
					tempId={tempId}
					refundData={refundData}
					readOnly={readOnly}
				/>
			)}
		</>
	);
};

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { setLoading, triggerNotification })(
	translationHook(InvoiceInfo)
);
